import { useEffect, useState } from "react";
import axios from "axios";
import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import swal from "sweetalert";
import "../withdraw/withdraw.css";

function Promo({ profile, getProfile }) {
    const [newMoney, setNewMoney] = useState(null);
    const [setting, setSetting] = useState(JSON.parse(localStorage.getItem("setting")));
    const [payment, setPayment] = useState(null);
    const {
        register,
        handleSubmit,
        setError,
        formState: { errors }
    } = useForm();
    axios.interceptors.request.use(
        (config) => {
            const token = sessionStorage.getItem("user");
            if (token) {
                config.headers["Authorization"] = `Bearer ${token}`;
            }
            return config;
        },
        (error) => {
            return Promise.reject(error);
        }
    );
    const navigate = useNavigate();
    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_URL}/setting/get`, {}).then((res) => {
            if (setting !== res.data.data[0]) {
                setSetting(res.data.data[0]);
                localStorage.setItem("setting", JSON.stringify(res.data.data[0]));
            }
        });
        axios
            .get(`${process.env.REACT_APP_API_URL}/payment/paymentus`, {})
            .then((res) => {
                setPayment(res.data.data);
            })
            .catch((err) => console.log(err));
    }, []);
    function formatDate(m) {
        new Date(m);
        const dateString =
            m.getUTCFullYear() +
            "/" +
            ("0" + (m.getMonth() + 1)).slice(-2) +
            "/" +
            ("0" + m.getDate()).slice(-2) +
            "  " +
            ("0" + m.getHours()).slice(-2) +
            ":" +
            ("0" + m.getMinutes()).slice(-2);
        return dateString;
    }
    const activeCode = (data) => {
        if (data.code == "") {
            swal("Thông báo", "Vui lòng nhập đầy đủ thông tin", "warning");
        }
        axios
            .post(`${process.env.REACT_APP_API_URL}/promo/active`, {code: data.code})
            .then((res) => {
                swal("Thành Công", `Mã khuyến mãi đã được áp dụng thành công! Bạn đã nhận được ${res.data.data.money.toLocaleString('vi-VN')}.`, "success").then(() => window.location.reload());
                getProfile();
            })
            .catch((err) => swal("Thất bại", err.response.data.message != "internal server error" ? err.response.data.message : "Đã xảy ra lỗi", "error"));
    };
    return (
        <div className="withdraw">
            {profile && (
                <>
                    <div className="withdraw-header">
                        <div className="menu-item-withdraw active">Nhập mã khuyến mãi</div>
                    </div>
                    <div className="withdraw-grid">
                        <div className="withdraw-grid-content">
                            <form onSubmit={handleSubmit(activeCode)}>
                                <div className="inputs">
                                    <div className="input">
                                        <label>Mã khuyến mãi</label>
                                        <input className="ipadd" type="text" {...register("code")} placeholder="Vui lòng nhập mã khuyến mãi" />
                                    </div>
                                    <button type="submit" className="btn-red">
                                        Áp dụng
                                    </button>
                                </div>
                            </form>
                        </div>
                        <div className="widthdraw-history">
                            <div className="history-title">Lịch sử khuyến mãi</div>
                            <div className="history-list">
                                {payment?.map((item, key) => (
                                    <>
                                        {item?.type_payment === "ƯU ĐÃI" && (
                                            <>
                                                <div className="js-withdraw-history">
                                                    <div className="item-inner">
                                                        <div className="item_history">
                                                            <span className="money">{Math.floor(item?.money).toLocaleString("vi-VN")}₫</span>
                                                            <span
                                                                className={`text_status ${
                                                                    item?.status_payment === "Pending" ? "pending" : item?.status_payment === "Success" ? "win" : "lose"
                                                                }`}>
                                                                {item?.status_payment === "Pending" ? "Chờ xử lý" : item?.status_payment === "Success" ? "Thành công" : "Thất bại"}
                                                            </span>
                                                        </div>
                                                        <div className="time_history">{formatDate(new Date(item?.createdAt))}</div>
                                                    </div>
                                                </div>
                                            </>
                                        )}
                                    </>
                                ))}
                            </div>
                        </div>
                    </div>
                </>
            )}
        </div>
    );
}
export default Promo;
