import { useEffect, useState } from "react";
import axios from "axios";
import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import swal from "sweetalert";
import "./withdraw.css";

function WithDraw({ profile, bankCard, getProfile }) {
    const [bank, setBank] = useState(null);
    const [newMoney, setNewMoney] = useState(null);
    const [setting, setSetting] = useState(JSON.parse(localStorage.getItem("setting")));
    const [payment, setPayment] = useState(null);
    const {
        register,
        handleSubmit,
        setError,
        formState: { errors }
    } = useForm();
    axios.interceptors.request.use(
        (config) => {
            const token = sessionStorage.getItem("user");
            if (token) {
                config.headers["Authorization"] = `Bearer ${token}`;
            }
            return config;
        },
        (error) => {
            return Promise.reject(error);
        }
    );
    const navigate = useNavigate();
    useEffect(() => {
        axios
            .get(`${process.env.REACT_APP_API_URL}/bank/getBank`, {})
            .then((res) => {
                setBank(res.data.data);
            })
            .catch((err) => setBank(null));
        axios.get(`${process.env.REACT_APP_API_URL}/setting/get`, {}).then((res) => {
            if (setting !== res.data.data[0]) {
                setSetting(res.data.data[0]);
                localStorage.setItem("setting", JSON.stringify(res.data.data[0]));
            }
        });
        axios
            .get(`${process.env.REACT_APP_API_URL}/payment/paymentus`, {})
            .then((res) => {
                setPayment(res.data.data);
            })
            .catch((err) => console.log(err));
    }, [profile]);
    function formatDate(m) {
        new Date(m);
        const dateString =
            m.getUTCFullYear() +
            "/" +
            ("0" + (m.getMonth() + 1)).slice(-2) +
            "/" +
            ("0" + m.getDate()).slice(-2) +
            "  " +
            ("0" + m.getHours()).slice(-2) +
            ":" +
            ("0" + m.getMinutes()).slice(-2);
        return dateString;
    }
    const onSubmit = (data) => {
        if (Number(data.money.replaceAll(".", "").replaceAll(",", "")) > profile.money) {
            swal("Thất bại", "Số dư không đủ", "error");
            return;
        }
        if (Number(data.money.replaceAll(".", "").replaceAll(",", "")) <= 0 || typeof Number(data.money.replaceAll(".", "").replaceAll(",", "")) !== "number") {
            swal("Chú ý", "Vui lòng nhập số tiền hợp lệ", "warning");
            return false;
        }
        if (!data.paypwd) {
            swal("Chú ý", "Vui lòng nhập mật khẩu rút tiền", "info");
            return false;
        }
        if (Number(data.money.replaceAll(".", "").replaceAll(",", "")) < setting?.minrut) {
            swal("Chú ý", `Số tiền rút tối thiểu là ${(setting?.minrut).toLocaleString('vi-VN')}`, "warning");
            return false;
        }
        if (Number(data.money.replaceAll(".", "").replaceAll(",", "")) > setting?.maxrut) {
            swal("Chú ý", `Số tiền rút tối đa là ${(setting?.maxrut).toLocaleString('vi-VN')}`, "warning");
            return false;
        }
        const formData = {
            money: Number(data.money.replaceAll(".", "").replaceAll(",", "")),
            type_payment: "RÚT",
            detail: bank[0]?.stk + " - " + bank[0]?.name_bank + " - " + bank[0]?.fullname,
            status_payment: "Pending",
            user: profile._id,
            paypwd: data.paypwd ? data.paypwd : ""
        };
        axios
            .post(`${process.env.REACT_APP_API_URL}/payment/withDraw`, formData)
            .then((res) => {
                swal({
                    title: "Thông báo",
                    text: "Tạo yêu cầu rút tiền thành công!",
                    icon: "success",
                    buttons: "OK"
                }).then(() => window.location.reload());
                getProfile();
            })
            .catch((err) => swal("Thất bại", err.response.data.message != "internal server error" ? err.response.data.message : "Đã xảy ra lỗi", "error"));
    };
    const [activeOption, setActiveOption] = useState(1);
    const handleOptionClick = (option) => {
        setActiveOption(option);
    };
    useEffect(() => {
        setActiveOption(2);
    }, [bankCard]);
    const addBank = (data) => {
        if (!data.oldpassword) {
            swal("Thông báo", "Vui lòng nhập mật khẩu cũ", "warning");
        }
        if (data.pass2.length < 6) {
            swal("Thông báo", "Mật khẩu rút tiền tối thiểu 6 kí tự", "warning");
        }
        if (data.name_bank == "" || data.stk == "" || data.fullname == "") {
            swal("Thông báo", "Vui lòng nhập đầy đủ thông tin", "warning");
        }
        const formData = {
            name_bank: data.name_bank,
            stk: data.stk,
            fullname: data.fullname,
            user: profile._id,
            pass2: data.pass2
        };
        axios
            .post(`${process.env.REACT_APP_API_URL}/bank/create`, formData)
            .then((res) => {
                swal("Thành Công", "Ngân hàng đã được thêm thành công", "success").then(() => window.location.reload());
                getProfile();
            })
            .catch((err) => swal("Thất bại", err.response.data.message != "internal server error" ? err.response.data.message : "Đã xảy ra lỗi", "error"));
    };
    return (
        <div className="withdraw">
            {profile && (
                <>
                    <div className="withdraw-header">
                        <div className={`menu-item-withdraw ${activeOption == 1 ? "active" : ""}`} onClick={() => handleOptionClick(1)}>
                            Rút tiền
                        </div>
                        <div className={`menu-item-withdraw ${activeOption == 2 ? "active" : ""}`} onClick={() => handleOptionClick(2)}>
                            Quản lý ngân hàng
                        </div>
                    </div>
                    <div className="withdraw-grid">
                        <div className="withdraw-grid-content">
                            {activeOption == 1 ? (
                                <div className="content-withdraw">
                                    <form onSubmit={handleSubmit(onSubmit)}>
                                        <div>
                                            {bank == null ? (
                                                <>
                                                    <div className="noBackard">
                                                        <div className="bg"></div>
                                                        <p>Thẻ ngân hàng trống</p>
                                                        <div className="btn-red" onClick={() => handleOptionClick(2)}>
                                                            Thêm Thẻ Ngân Hàng
                                                        </div>
                                                    </div>
                                                </>
                                            ) : (
                                                <>
                                                    <div className="input">
                                                        <label>Số tiền rút</label>
                                                        <input
                                                            className="ipadd"
                                                            type="text"
                                                            value={newMoney}
                                                            {...register("money")}
                                                            onClick={() => setNewMoney(null)}
                                                            onChange={(e) => setNewMoney(Number(e.target.value.replaceAll(".", "")).toLocaleString('vi-VN'))}
                                                            placeholder="Nhập số tiền"
                                                        />
                                                    </div>
                                                    <div className="input">
                                                        <label>Mật khẩu rút tiền</label>
                                                        <input className="ipadd" type="password" {...register("paypwd")} placeholder="Nhập mật khẩu rút tiền" />
                                                    </div>
                                                    <button type="submit" className="btn-red">
                                                        Xác nhận
                                                    </button>
                                                </>
                                            )}
                                        </div>
                                    </form>
                                </div>
                            ) : (
                                <>
                                    {bank == null ? (
                                        <form onSubmit={handleSubmit(addBank)}>
                                            <div className="inputs">
                                                <div className="input">
                                                    <label>Tên ngân hàng</label>
                                                    <input className="ipadd" type="text" {...register("name_bank")} />
                                                </div>
                                                <div className="input">
                                                    <label>Số tài khoản</label>
                                                    <input className="ipadd" type="text" {...register("stk")} />
                                                </div>
                                                <div className="input">
                                                    <label>Tên chủ tài khoản</label>
                                                    <input className="ipadd" type="text" {...register("fullname")} />
                                                </div>
                                                <div className="input">
                                                    <label>Mật khẩu rút tiền</label>
                                                    <input className="ipadd" type="password" {...register("pass2")} />
                                                </div>
                                                <button type="submit" className="btn-red">
                                                    Xác nhận
                                                </button>
                                            </div>
                                        </form>
                                    ) : (
                                        <>
                                            {bank.map((item) => (
                                                <>
                                                    <div className="box-banking" onClick={() => swal("Chú ý", "Để chỉnh sửa thông tin ngân hàng vui lòng liên hệ CSKH", "info")}>
                                                        <div className="money_banking">
                                                            <h3>{item.fullname}</h3>
                                                            <h4>{item.stk}</h4>
                                                        </div>
                                                        <div className="ctk">{item.name_bank}</div>
                                                        <div className="icon_credit">
                                                            <img alt="" src={require("../../../image/icon_credit.png")} />
                                                        </div>
                                                    </div>
                                                </>
                                            ))}
                                        </>
                                    )}
                                    <div className="huongdan">
                                        <div className="title">Chú ý</div>
                                        <ul>
                                            <li>Thông tin liên kết ngân hàng phải tuyệt đối chính xác.</li>
                                            <li>Chúng tôi không chịu trách nhiệm cho sai sót liên quan đến ngân hàng của bạn.</li>
                                            <li>Để chỉnh sửa thông tin ngân hàng nếu bạn đã liên kết, vui lòng liên hệ CSKH.</li>
                                        </ul>
                                    </div>
                                </>
                            )}
                        </div>
                        <div className="widthdraw-history">
                            <div className="history-title">Lịch sử rút tiền</div>
                            <div className="history-list">
                                {payment?.map((item, key) => (
                                    <>
                                        {item?.type_payment === "RÚT" && (
                                            <>
                                                <div className="js-withdraw-history">
                                                    <div className="item-inner">
                                                        <div className="item_history">
                                                            <span className="money">{Math.floor(item?.money).toLocaleString('vi-VN')}₫</span>
                                                            <span
                                                                className={`text_status ${
                                                                    item?.status_payment === "Pending" ? "pending" : item?.status_payment === "Success" ? "win" : "lose"
                                                                }`}>
                                                                {item?.status_payment === "Pending" ? "Chờ xử lý" : item?.status_payment === "Success" ? "Thành công" : "Thất bại"}
                                                            </span>
                                                        </div>
                                                        <div className="time_history">{formatDate(new Date(item?.createdAt))}</div>
                                                    </div>
                                                </div>
                                            </>
                                        )}
                                    </>
                                ))}
                            </div>
                        </div>
                    </div>
                </>
            )}
        </div>
    );
}
export default WithDraw;
