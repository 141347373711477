import { Link, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import axios from "axios";
import swal from "sweetalert";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import KeyboardArrowLeftOutlinedIcon from "@mui/icons-material/KeyboardArrowLeftOutlined";
import PhoneIcon from "@mui/icons-material/Phone";

const Login = ({ regClick, getProfile }) => {
    const [err, setErr] = useState(null);
    const login = sessionStorage.getItem("user");
    const [profile, setProfile] = useState(null);
    const navigate = useNavigate();
    const {
        register,
        handleSubmit,
        setError,
        formState: { errors }
    } = useForm();
    useEffect(() => {
        if (login) {
            navigate("/");
        }
        axios
            .get(`${process.env.REACT_APP_API_URL}/auth/getUser`, {})
            .then((res) => {
                if (profile != res.data.data) {
                    setProfile(res.data.data);
                    localStorage.setItem("profile", JSON.stringify(res.data.data));
                }
            })
            .catch((err) => sessionStorage.removeItem("user"));
    }, []);
    const onSubmit = async (data) => {
        const regex = /^[a-zA-Z0-9]+$/;
        if (data.username.length < 6) {
            setError("username", {
                type: "minLength",
                message: "Tên đăng nhập tối thiểu 6 kí tự"
            });
            return;
        }
        if (!regex.test(data.username)) {
            setError("username", {
                type: "minLength",
                message: "Tên đăng nhập không chứa khoảng trắng và ký tự đặc biệt"
            });
            return;
        }
        if (data.password.length < 6) {
            setError("password", {
                type: "minLength",
                message: "Mật khẩu tối thiểu 6 kí tự"
            });
            return;
        }
        axios
            .post(`${process.env.REACT_APP_API_URL}/auth/login`, {
                username: data.username.toLowerCase(),
                password: data.password
            })
            .then((res) => {
                swal({
                    title: "Thông báo",
                    text: "Đăng nhập thành công",
                    icon: "success",
                    buttons: "OK"
                }).then(() => {
                    sessionStorage.removeItem("user");
                    localStorage.removeItem("profile");
                    localStorage.removeItem("data");
                    sessionStorage.setItem("user", res.data.data);
                    window.location.href = "/";
                });
            })
            .catch((err) => setErr(err.response.data.message ? err.response.data.message : "Tên đăng nhập hoặc mật khẩu không chính xác!"));
    };
    const [showPassword, setShowPassword] = useState(false);
    const toggleShowPassword = () => {
        setShowPassword(!showPassword);
    };
    return (
        <>
            <form className="form-lg" onSubmit={handleSubmit(onSubmit)}>
                <div>
                    <img alt="" src="/images/logo1.png.avif" width="135" className="logo" />
                    <h1>ĐĂNG NHẬP</h1>
                </div>
                <div className="inputs">
                    <div className="input">
                        <img alt="" src={require("../../static/i_user.png")} />
                        <input type="text" className="ip-lg" {...register("username", { required: true })} placeholder="Tên đăng nhập" />
                        {errors.username ? <p>{errors.username.message}</p> : null}
                    </div>
                    <div className="input">
                        <img alt="" src={require("../../static/i_pasw.png")} />
                        <input type={showPassword ? "text" : "password"} className="ip-lg" {...register("password", { required: true })} placeholder="Mật khẩu" />
                        {showPassword ? <Visibility onClick={toggleShowPassword} /> : <VisibilityOff onClick={toggleShowPassword} />}
                        {errors.password ? <p>{errors.password.message}</p> : null}
                    </div>
                </div>
                {err ? <p>{err}</p> : null}
                <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr", padding: "0 0 20px", color: "#fff" }}>
                    <a onClick={regClick}>
                        Đăng ký
                    </a>
                    <Link to="/service">Quên mật khẩu</Link>
                </div>
                <button className="btn-red-big" type="submit">
                    ĐĂNG NHẬP
                </button>
            </form>
        </>
    );
};
export default Login;
